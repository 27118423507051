/* eslint-disable @typescript-eslint/no-explicit-any */
import {useCallback} from 'react';
import NextHead from 'next/head';
import {config} from '@fortawesome/fontawesome-svg-core';
import {useRouter} from 'next/router';
import Bugsnag from 'bugsnag';
import {AppShell} from 'components/AppShell';
import {Analytics, useAnalytics} from 'components/Analytics';
import {Head, AppProvider} from '@halp/foundation/App';
import '@fortawesome/fontawesome-svg-core/styles.css';
import '@halp/css';
import type {SessionUser} from '@halp/foundation/Users';
import {I18nProvider, useI18n} from '@halp/foundation/i18n';
import type {AppProps} from 'next/app';

config.autoAddCss = false; // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

export default function Halp(props: AppProps) {
	const router = useRouter();
	const ErrorBoundary = Bugsnag.getPlugin('react') as any;

	return (
		<ErrorBoundary>
			<I18nProvider
				lngDict={props.pageProps.lngDict}
				locale={router.locale ?? 'en'}
				onError={Bugsnag.notify}
			>
				<Analytics />
				<App {...props} />
			</I18nProvider>
		</ErrorBoundary>
	);
}

function App({Component, pageProps}: AppProps) {
	const {t} = useI18n();
	const analytics = useAnalytics();

	const userLoaded = useCallback(
		(user: SessionUser) => {
			Bugsnag.setUser(
				user.uid ?? undefined,
				user.email ?? undefined,
				user.name ?? undefined,
			);

			Bugsnag.addFeatureFlags(
				user.enabledFeatures.map((feature) => ({name: feature})),
			);

			analytics.identify(user.id, {
				id: user.id,
				// eslint-disable-next-line @typescript-eslint/naming-convention
				created_at: user.insertedAt,
				email: user.email,
				// eslint-disable-next-line @typescript-eslint/naming-convention
				first_name: user.first,
				// eslint-disable-next-line @typescript-eslint/naming-convention
				last_name: user.last,
				phone: user.phone,
				funnelStage: user.studentProfile?.funnelStage?.toLowerCase(),
				refer: user.referralSource,
				country: user.country,
				coach: user.studentProfile?.coach?.name,
				// eslint-disable-next-line @typescript-eslint/naming-convention
				account_status: user?.studentProfile?.verified
					? 'member'
					: 'non-member',
				// eslint-disable-next-line @typescript-eslint/naming-convention
				qualification_status: user?.studentProfile?.qualificationStatus,
				// eslint-disable-next-line @typescript-eslint/naming-convention
				qualification_score: user?.studentProfile?.qualificationScore,
				// eslint-disable-next-line @typescript-eslint/naming-convention
				target_study_date: user?.studentProfile?.targetStudyDate,
			});
		},
		[analytics],
	);

	return (
		<AppProvider
			logoutMessage={t('logged_out_confirmation')}
			onUserLoaded={userLoaded}
		>
			<Head />
			<NextHead>
				<meta charSet="utf-8" />
				<meta httpEquiv="X-UA-Compatible" content="IE=edge" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta
					name="facebook-domain-verification"
					content="qta2vl7sfmaokxyvvup79o6r0h2oml"
				/>
				<link rel="icon" type="image/png" href="/favicon.png" />
			</NextHead>
			<AppShell>
				<Component {...pageProps} />
			</AppShell>
			<div id="modal-root" />
			<div id="portal-root" />
		</AppProvider>
	);
}
