import {useRouter} from 'next/router';
import {Stack, Heading, Link} from '@halp/ui';
import {useI18n} from '@halp/foundation/i18n';
import {Paths} from '@halp/foundation/Paths';
import {useUser} from '@halp/foundation/Users';

export function ArchivedBanner() {
	const {t} = useI18n();
	const router = useRouter();
	const {user} = useUser();
	return (
		<Link
			onClick={() =>
				router.push(
					Paths.student.survey.archivedUnresponsive.url(
						user?.studentProfile?.id ?? '',
					),
				)
			}
		>
			<Stack direction="column">
				<Heading type="h5" color="white" align="center">
					{t('student_dashboard.dashboard.banner.archived.account_paused')}
				</Heading>
				<Heading type="h5" color="white" align="center">
					{t('student_dashboard.dashboard.banner.archived.improve_services')}
				</Heading>
			</Stack>
		</Link>
	);
}
