import {Avatar, Link, Paragraph, Heading, Surface, Stack, Icon} from '@halp/ui';
import {haPhone, haTextMessage} from '@halp/icons';
import {useI18n} from '../../i18n';

interface Props {
	name?: string | null;
	contactPhoneNumber?: string | null;
	photo?: string | null;
	calendlyUrl?: string | null;
	hideDetails?: boolean;
}

// TODO: @jtsmills - icon package

export function CoachCard({
	name,
	contactPhoneNumber,
	photo,
	calendlyUrl,
	hideDetails = false,
}: Props) {
	const {t} = useI18n();

	const booking = calendlyUrl ? (
		<Stack direction="row" spacing="xxs">
			<Icon icon={haPhone} color="purple" />
			<Link href={calendlyUrl} target="_blank">
				<Paragraph type="subtitle2" weight="semibold" color="black">
					{t('nav.student.coach_card.book_a_call')}
				</Paragraph>
			</Link>
		</Stack>
	) : null;

	const contact = contactPhoneNumber ? (
		<Stack direction="row" spacing="xxs">
			<Icon icon={haTextMessage} color="purple" />
			<Paragraph type="subtitle2" weight="semibold" color="black">
				{contactPhoneNumber}
			</Paragraph>
		</Stack>
	) : null;

	const avatar = (
		<Avatar image={photo} text={name} size={hideDetails ? 'small' : 'medium'} />
	);

	return (
		<>
			{hideDetails ? (
				avatar
			) : (
				<Surface shadow="none" padding="none">
					<Stack direction="column" spacing="sm" alignItems="flex-start">
						<Stack direction="row" spacing="xs">
							{avatar}
							<Stack direction="column" alignItems="flex-start">
								<Paragraph type="caption" color="grey">
									{t('nav.student.coach_card.info')}
								</Paragraph>
								<Heading type="h5" weight="bold">
									{name}
								</Heading>
							</Stack>
						</Stack>
						<Stack direction="column" spacing="xs">
							{booking}
							{contact}
						</Stack>
					</Stack>
				</Surface>
			)}
		</>
	);
}
