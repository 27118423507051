import {useI18n} from '@halp/foundation/i18n';
import {Stack, Heading} from '@halp/ui';
import type {FunnelStage} from '@halp/util/constants';
import {dayjs, formatDateTime} from '@halp/util/datetime';

export function DeadlineBanner({
	funnelStage,
	deadline,
	studyDate,
}: {
	funnelStage: FunnelStage;
	deadline: Date;
	studyDate: Date;
}) {
	const {t} = useI18n();
	const daysToDeadline = dayjs(deadline).diff(new Date(), 'days');
	const daysToStudyDate = dayjs(studyDate).diff(new Date(), 'days');

	let deadlineMessage;
	if (funnelStage === 'ACCEPTED') {
		if (daysToDeadline >= 15) {
			deadlineMessage = t(
				'student_dashboard.dashboard.banner.deadline.accepted_warning',
			);
		} else {
			deadlineMessage = t(
				'student_dashboard.dashboard.banner.deadline.accepted_severe',
				{
					daysToDeadline: daysToDeadline,
				},
			);
		}
	} else if (funnelStage === 'VISA') {
		if (daysToStudyDate <= 60) {
			deadlineMessage = t(
				'student_dashboard.dashboard.banner.deadline.visa_severe',
				{
					daysToDeadline: daysToDeadline,
					intake: formatDateTime(studyDate, 'DateShort'),
				},
			);
		} else {
			deadlineMessage = t(
				'student_dashboard.dashboard.banner.deadline.visa_warning',
			);
		}
	}

	return (
		<Stack direction="column">
			<Heading type="h5" color="white" align="center">
				{deadlineMessage}
			</Heading>
		</Stack>
	);
}
